import { ethers } from 'ethers'
import { fromFetch } from 'rxjs/fetch';
import { from, of, map, switchMap, Observable } from "rxjs"
import { IERC721Enumerable__factory, IERC721Enumerable, IERC721Metadata, IERC721Metadata__factory } from '../cache/typings/';

export function contractAddress(): string {
    return "0xcF8CF01cBA153497b0AAc34C6ce3B52017fBAaFc"
}

export function ERC721Enumerable(): IERC721Enumerable {
    return IERC721Enumerable__factory.connect(contractAddress(), ethers.providers.getDefaultProvider())
}

export function ERC721Metadata(): IERC721Metadata {
    return IERC721Metadata__factory.connect(contractAddress(), ethers.providers.getDefaultProvider())
}

export function getTokenURI(tokenId: string): Promise<string> {
    return ERC721Metadata().tokenURI(tokenId)
}

export function tokenURIObs(tokenId: string): Observable<any> {
    return from(getTokenURI(tokenId)).pipe(
        map(x => ({ name: "uri", value: x }))
    )
}

export function getOwner(tokenId: string): Promise<string> {
    return ERC721Enumerable().ownerOf(tokenId)
}

export function ownerObs(tokenId: string): Observable<any> {
    return from(getOwner(tokenId)).pipe(
        map(x => ({ name: "owner", value: x }))
    )
}

export function txHashObs(tx: string): Observable<any> {

    const url = "https://api.etherscan.io/api?module=account&action=txlistinternal&txhash=" + tx + "&apikey=1X4IR2C2QABE9TY7K5AZ868FF7SEISQ2QT"
    //    const url = "http://testsharepai.oss-cn-beijing.aliyuncs.com/interaltxtest.json"
    //    const url = "http://testsharepai.oss-cn-beijing.aliyuncs.com/interaltxerror.json"

    return fromFetch(url).pipe(
        switchMap(response => {
            if (response.ok) {
                // OK return data
                return response.json();
            } else {
                // Server is returning a status requiring the client to try something else.
                return of({ error: true, message: `Error ${response.status}` });
            }
        }),
        map(x => parseTokenId(x)),
        // catchError(err => {
        //     // Network or other error, handle appropriately
        //     console.error("catchError" + err);
        //     return of({ error: true, message: err.message })
        // })
    );
}

// {
//     "status":"1",
//     "message":"OK",
//     "result": [
//         {
//             "blockNumber":"15325333",
//             "timeStamp":"1660284393",
//             "from":"0xcf8cf01cba153497b0aac34c6ce3b52017fbaafc",
//             "to":"0x15dcc6ac5315b70aed971465cb2deaa1f1e8aba7",
//             "value":"898868",
//             "contractAddress":"",
//             "input":"",
//             "type":"call",
//             "gas":"2300",
//             "gasUsed":"0",
//             "isError":"0",
//             "errCode":""
//         },
//         {
//             "blockNumber":"15325333","timeStamp":"1660284393","from":"0xcf8cf01cba153497b0aac34c6ce3b52017fbaafc","to":"0x335db831a9c0a216dacaf3e2b152a84231e6ef6b","value":"9999999999101132","contractAddress":"","input":"","type":"call","gas":"2300","gasUsed":"0","isError":"0","errCode":""
//         }
//     ]
// }

export function parseTokenId(obj: object) {

    const dict = obj as { [key: string]: any }
    const result = dict['result'] as { [key: string]: string }[]
    if (result.length !== 2) {
        throw new Error("Invalid transaction hash.")
    }

    const code = result[0]['value']
    if (code.length !== 6) {
        throw new Error("Invalid transaction hash.")
    }

    return code
}