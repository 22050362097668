import React from 'react';
import {CssBaseline, Container, Snackbar, Alert, Backdrop, CircularProgress} from '@mui/material';
import SearchAppBar from './components/SearchBar'
import HomeView from './views'
import { useSelector, AppState, useDispatch, AppDispatch, actionErrMsg} from "./store"

function App() {
  const errMsg = useSelector<AppState, string>((state) => state.ui.errMsg)
  const loading = useSelector<AppState, boolean>((state) => state.ui.showLoading)

  const dispatch = useDispatch<AppDispatch>()

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    dispatch(actionErrMsg(''))
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <SearchAppBar />
      <Snackbar open={errMsg.length > 0} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
//        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <HomeView />
      </Container>
    </React.Fragment>
  );
}

export default App;
