import { configureStore } from '@reduxjs/toolkit'
import appReducer from './reducers/ui'
import tokenReducer from './reducers/token'

const store = configureStore({
  reducer: {
      ui: appReducer,
      token: tokenReducer,
    }
})

export default store

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>

export { useSelector, useDispatch } from 'react-redux';
export * from './actions'