
  export function validateTokenId(str: string): boolean {
    return /^([6789]{6})$/.test(str)
  }

  export function validateTxHash(str: string): boolean {
    return /^0x([A-Fa-f0-9]{64})$/.test(str)
  }
 
  export function etherError(err: Error): string {
    let reg: RegExp = /reason="(.*)"/
    if (!reg.test(err.message)) {
      return ""
    }

    let result = reg.exec(err.message)
    return result!.at(1)!
  }