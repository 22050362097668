import React from 'react';
import { Dialog, DialogTitle, Typography } from '@mui/material';
import { Card, CardMedia, CardContent } from '@mui/material';
import QRCode from 'qrcode';
import {contractAddress} from '../utils'

export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;
  }
  
function QRcodeDialog(props: SimpleDialogProps) {

    const { open, onClose } = props;
    const [qr, setQr] = React.useState('')

    const handleClose = () => {
        onClose();
    };

    React.useEffect(() => {

        QRCode.toDataURL(contractAddress(), {
            width: 200,
            margin: 2,
            color: {
                // dark: '#335383FF',
                // light: '#EEEEEEFF'
            }
        }, (err, url) => {
            if (err) return console.error(err)
            console.log(url)
            setQr(url)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>NFTich contract</DialogTitle>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    image={qr}
                    alt="Paella dish"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    Anyone who transfers 0.01ETH to NFTich contract for divination will have the opportunity to get the divination results (hexagrams) NFT.
                    </Typography>
                </CardContent>
            </Card>
        </Dialog>
    );
}

export default QRcodeDialog