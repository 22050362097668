
import { SvgIcon } from '@mui/material';

function TaijiIcon(props: any) {
    return (
        <SvgIcon viewBox="-40 -40 80 80" {...props}>
            <circle r="39" fill="#000"/>
            <path fill="#fff" d="M0,38a38,38 0 0 1 0,-76a19,19 0 0 1 0,38a19,19 0 0 0 0,38" />
            <circle r="5" cy="19" fill="#fff" />
            <circle r="5" cy="-19" fill="#000"/>
        </SvgIcon>
    );
}

export default TaijiIcon;