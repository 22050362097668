import { createReducer } from '@reduxjs/toolkit'
import { actionErrMsg, actionLoading } from '../actions'

interface State {
    errMsg: string
    showLoading: boolean
}

const initialState: State = {
    errMsg: '',
    showLoading: false
}

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionErrMsg, (state, action) => {
            state.errMsg = action.payload
        })
        .addCase(actionLoading, (state, action) => {
            state.showLoading = action.payload
        })
})

export default reducer;