import React from 'react';
import {Avatar, Typography} from "@mui/material"
import {Card, CardHeader, CardMedia, CardContent} from "@mui/material"
import TaijiIcon from '../Icons/Taiji'
import { red } from '@mui/material/colors';
import { useSelector, AppState} from "../store"

function HomeView() {
    const tokenId = useSelector<AppState, string>((state) => state.token.tokenId)
    const tokenOwner = useSelector<AppState, string>((state) => state.token.tokenOwner)
    const tokenURI = useSelector<AppState, string>((state) => state.token.tokenURI)

    return (
          <Card variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
  
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }}>
                  <TaijiIcon />
                </Avatar>
              }
              title="token Id:"
              subheader={tokenId}
            />
            <CardMedia
              component="img"
              image={tokenURI}
              alt={tokenId}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                所有者:
              </Typography>
              <Typography gutterBottom variant="h5" component="div" style={{"wordWrap": "break-word"}} >
                {tokenOwner}
              </Typography>
            </CardContent>
          </Card>
    )
}

export default HomeView