import React from 'react';
import { InputBase, AppBar, Toolbar, Typography, IconButton, Box, InputAdornment } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenSeaIcon from '../Icons/OpenSea';
import { ownerObs, tokenURIObs, txHashObs } from '../utils/index'
import { validateTokenId, validateTxHash, etherError } from '../utils/verify'
import { of, map, mergeAll, concatAll, Observable } from "rxjs"
import { useDispatch, AppDispatch, useSelector, AppState, actionTokenId, actionTokenOwner, actionTokenURI, actionErrMsg, actionLoading } from "../store"
import QRcodeDialog from './QRcodeDialog'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function SearchBar() {

  const [searchKey, setSearchKey] = React.useState<string>("")
  const [dialog, setDialog] = React.useState<boolean>(false)
  const id = useSelector<AppState, string>((state) => state.token.tokenId)

  const dispatch = useDispatch<AppDispatch>()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let key = event.target.value
    setSearchKey(key)

    //
    let oldId = id
    var obs: Observable<any>
    if (validateTokenId(key)) {
      obs = of(ownerObs(key), tokenURIObs(key), of({name: "id", value: key})).pipe(mergeAll())
    } else if (validateTxHash(key)) {
      obs = txHashObs(key).pipe(
        map(x => of(ownerObs(x), tokenURIObs(x), of({name: "id", value: x})).pipe(mergeAll())),
        mergeAll()
      )
    } else {
      console.log("invalid search key, just return.")
      return
    }

    //
    let obs1 = of({name: "loading", value: true})
    let obs2 = of({name: "loading", value: false})
    of(obs1, obs, obs2).pipe(
      concatAll()
    )
    .subscribe({
      next: next,
      error: function (error) {
        dispatch(actionLoading(false))
        dispatch(actionTokenId(oldId))

        let errMsg = etherError(error)
        dispatch(actionErrMsg(errMsg.length > 0 ? errMsg : error.message))
        console.log(error.message)
      },
//      complete: () => dispatch(actionLoading(false))
    });
};

const next = (value: { [key: string]: string }) => {
  
  if (value.name === "loading") {
    console.log("loading: " + value.value)
    dispatch(actionLoading(Boolean(value.value)))
  }

  if (value.name === "id") {
    console.log("id: " + value.value)
    dispatch(actionTokenId(value.value))
  }
  
  if (value.name === "owner") {
    console.log("owner: " + value.value)
    dispatch(actionTokenOwner(value.value))
  }

  if (value.name === "uri") {
    console.log("uri:" + value.value)
    dispatch(actionTokenURI(value.value))
  }
}

return (
  <Box sx={{ flexGrow: 1 }}>
    <QRcodeDialog open={dialog} onClose={()=>setDialog(false)} />
    <AppBar position="sticky" color="primary">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2 }}
          onClick = {() => setDialog(true) }
        >
          <HomeIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          NFTich
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Transaction hash/Token Id"
            inputProps={{ 'aria-label': 'search' }}
            name="searchString"
            value={searchKey}
            onChange={handleChange}
            endAdornment={
              searchKey.length > 0 && (<InputAdornment position="end">
                <IconButton
                  aria-label=""
                  size="small"
                  color="inherit"
                  onClick={()=> setSearchKey('')}
                  edge="end"
                >
                  <CancelIcon fontSize="inherit"/>
                </IconButton>
              </InputAdornment>)
            }
        />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Box >
          <IconButton size="large" color="inherit" onClick={() => window.open("https://opensea.io/collection/nftich")}>
              <OpenSeaIcon fontSize="inherit"/>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  </Box>
);
}

export default SearchBar
