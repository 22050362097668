import { createReducer } from '@reduxjs/toolkit'
import { actionTokenId, actionTokenOwner, actionTokenURI } from '../actions'

interface State {
    tokenId: string
    tokenOwner: string
    tokenURI: string
}

const initialState: State = {
    tokenId: "-",
    tokenOwner: "0x",
    tokenURI: "https://mui.com/static/images/cards/contemplative-reptile.jpg"
}

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionTokenId, (state, action) => {
            state.tokenId = action.payload
        })
        .addCase(actionTokenOwner, (state, action) => {
            state.tokenOwner = action.payload
        })
        .addCase(actionTokenURI, (state, action) => {
            state.tokenURI = action.payload
        })
})

export default reducer;